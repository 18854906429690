import React from "react";
import "./newlaunching.scss";

const newlaunching = () => {
  const wabrosur = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6282288886274&text=Halo%20Cornel,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(newlaunching+lacovia)%20https://marketingcitragardenserpong.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="newlaunching">
      <div className="main">
        <div className="title">NEW LAUNCHING LACOVIA</div>
        <div className="container-nl"></div>
      </div>
      <div className="button">
        <button className="nl" onClick={wabrosur}>
          Info New Launching
        </button>
      </div>
    </div>
  );
};

export default newlaunching;
