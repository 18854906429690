import React from "react";
import "./promo.scss";
import Promoimage from "../../asset/main/promo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faMedal } from "@fortawesome/free-solid-svg-icons";

const Promo = () => {
  const wapromo = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6282288886274&text=Halo%20Cornel,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Promo)%20https://marketingcitragardenserpong.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div id="promo" className="container-promo">
      <div className="dividera"></div>
      <div className=" container-listpromo">
        <div className="containercontent">
          <div className="judul-promo">Promo New Launching</div>
          <div className="subjudul-promo">ELAIA</div>
          <div className="pointpenawaran">
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faMedal} />
              <span>&nbsp;&nbsp;Free PPN 100%</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faMedal} />
              <span>&nbsp;&nbsp;Siap Huni</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faMedal} />
              <span>&nbsp;&nbsp;Free DP</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faMedal} />
              <span>&nbsp;&nbsp;Free BPHTB</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faMedal} />
              <span>&nbsp;&nbsp;Free AJB</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faMedal} />
              <span>&nbsp;&nbsp;Free Smartdoorlock</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faMedal} />
              <span>&nbsp;&nbsp;Free AC</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faMedal} />
              <span>&nbsp;&nbsp;Free Canopy</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faMedal} />
              <span>&nbsp;&nbsp;Free Provisi & Admin</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faMedal} />
              <span>&nbsp;&nbsp;Discount Launching 3%</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faMedal} />
              <span>&nbsp;&nbsp;Voucher Interior</span>
            </div>
          </div>
          <div className="disclaimer">*Disclaimer</div>
          <div className="button-wa-promo">
            <button onClick={wapromo} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} /> Get More Promo
            </button>
          </div>
        </div>
        <div className="containergambar">
          <img className="penawarangambar" src={Promoimage} alt="hardsell" />
        </div>
      </div>
      <div className="dividerb" />
    </div>
  );
};

export default Promo;
